.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--grid-gap);
  align-items: stretch;
}
.grid::after,
.grid .grid-item {
  flex: 1 1 var(--grid-max);
  min-width: var(--grid-min);
}
.grid::after {
  content: '';
  display: block;
}
.stack.stack-vertical > .stack-item + .stack-item {
  margin-top: var(--stack-gap);
}
@media screen and (max-width: 600px) {
  .stack.stack-horizontal > .stack-item + .stack-item {
    margin-top: var(--stack-gap);
  }
}
@media screen and (min-width: 601px) {
  .stack.stack-horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--stack-gap));
    overflow: hidden;
  }
  .stack.stack-horizontal .stack-item {
    flex: 1 1 0;
    margin-left: var(--stack-gap);
  }
}
.image img {
  width: 100%;
  display: block;
  object-fit: cover;
  max-height: 100%;
}
.video {
  width: 100%;
  display: block;
  object-fit: cover;
  max-height: 100%;
}
.cms-admin-actions {
  background: #333;
  position: fixed;
  top: 0;
  left: 20px;
  padding: 4px;
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  z-index: 2000;
}
@media screen and (max-width: 768px) {
  .cms-admin-actions {
    display: none;
  }
}
.cms-admin-actions a {
  color: #fff;
  border-radius: 2px;
  padding: 6px 11px;
  text-decoration: none;
}
.cms-admin-actions a:hover {
  background: #222;
}
